.cup {
  background-color: white;
  /* Apply background color */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(https://res.cloudinary.com/dtziypksf/image/upload/v1688264957/wave_hg0lwh.png);
  /* Use background-image property */
  background-position: 100px 300px;
  background-repeat: repeat-x;
  animation: filling 10s linear infinite;
}


@keyframes filling {
  50% {
    background-position: 3000px 300px;
  }

  100% {
    background-position: 6000px 250px;
  }
}