.ag-theme-alpine {
    --ag-foreground-color: rgb(60, 60, 80);
    --ag-background-color: rgb(255, 243, 209);
    --ag-header-foreground-color: rgb(60, 60, 60);
    --ag-header-background-color: rgb(253, 204, 33);
    --ag-odd-row-background-color: rgb(255, 255, 250);
    --ag-header-column-resize-handle-color: rgb(126, 46, 132);
    --ag-font-size: 14px;

    /* --ag-selected-row-background-color: rgb(255, 255, 255); */
    --ag-borders: none;
    /* then add back a border between rows */
    --ag-row-border-style: dashed;
    --ag-row-border-width: 1px;
    --ag-row-border-color: rgb(150, 150, 200);
}


.check-div {
    background-color: #EDEDED;
    border-radius: 20px;
    overflow: hidden;
    float: left;
    margin-top: 4px;
}

.check-div label {
    float: left;
    width: 6.0em;
    height: 2em;
}

.check-div label span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
}

.check-div label input {
    position: absolute;
    display: none;
    color: #fff !important;
}

.check-div label input+span {
    color: #fff;
}

.check-div input:checked+span {
    color: #ffffff;
}

.check-div input+span {
    font-size: 12px;
}

.background-color input:checked+span {
    background-color: #f183ff;
}